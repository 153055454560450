<template>
    <StepTemplate
        class="loan-application"
        :class="{
            'loan-application_first': $route.name === 'LoanPrimary'
        }"
        :modifier="modifier"
        :isLong="isLong"
        topClass="loan-application__top"
        titleClass="loan-application__title"
    >
        <template v-slot:bg>
            <Progress class="loan-application__bg-progress"/>
        </template>
        <template v-slot:title>
            {{ title }}
        </template>
        <template v-slot:top>
            <calc-result v-if="isSbg && !calcState"></calc-result>
        </template>
        <div class="loan-application__main">
            <div
                class="loan-application__content"
            >
                <div class="loan-application__form">
                    <router-view/>
                </div>
            </div>
        </div>
        <base-button-up></base-button-up>
    </StepTemplate>
</template>

<script>
import './loan-application.scss'

import sbg from '@/mixins/sbg'

export default {
    name: 'LoanApplication',
    mixins: [
        sbg,
    ],
    data() {
        return {
            past: 0,
            days: {
                type: 'days',
                count: 10
            },
            sum: 10000
        }
    },
    computed: {
        title() {
            return this.$DICTIONARY.applicationHeader
        },
        modifier() {
            switch (this.$route.name) {
                case 'LoanAuth':
                case 'LoanCard':
                    return 'city'
                case 'LoanContact':
                    return 'dark'
                case 'LoanBefore':
                    return 'man'
            }

            return 'green'
        },
        isLong() {
            return ['LoanBefore', 'LoanAuth', 'LoanCard'].includes(this.$route.name)
        },
        calcState() {
            return this.$route.name === 'LoanBefore' || this.$route.name === 'LoanAuth';
        }
    },
    components: {
        Progress: () => import('@/applications/loan-application/components/progress/Progress'),
        CalcResult: () => import('@/applications/loan-application/components/calc/CalcResult'),
    },
}
</script>